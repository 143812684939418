<template>
  <b-button
    size="sm"
    :variant="`${colorVariant()}`"
    :title="text_button"
    v-b-tooltip.topleft.v-secondary.noninteractive="
      tooltip_text ? tooltip_text : ''
    "
    @click="handleClick"
    class="noprint"
    :class="custom_class"
    :disabled="disabled_button"
  >
    <b-badge class="m-0 p-0" variant="success">
      <img
        v-if="show_icon && icon_excel"
        src="@/assets/new-page/icon-excel.svg"
        alt="Icono Excel"
      />
    </b-badge>
    <b-badge class="m-0 p-0" variant="primary">
      <img
        v-if="show_icon && icon_word"
        src="@/assets/new-page/icon-word.svg"
        alt="Icono Word"
      />
    </b-badge>
    <span v-if="text_button" class="ml-1">
      {{ text_button }}
    </span>
  </b-button>
</template>

<script>
export default {
  name: "GenericButtonDownload",
  props: {
    text_button: {
      type: String,
      default: "",
    },
    tooltip_text: {
      type: String,
      default: "",
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    // variant: {
    //   type: String,
    //   default: "secondary",
    // },
    show_icon: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
    icon_excel: {
      type: Boolean,
      default: false,
    },
    icon_word: {
      type: Boolean,
      default: false,
    },
    variant_button: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    colorVariant() {
      if (this.variant_button.length > 0) return this.variant_button;
      if (this.icon_excel && this.text_button == "") return "success";
      else if (this.icon_word && this.text_button == "") return "primary";
      else if (this.icon_excel || this.icon_word) return "secondary";
      else return "primary";
    },
    handleClick() {
      if (!this.disabled_button) {
        // Si se proporciona una función de click, se ejecuta
        if (this.click_button) {
          this.click_button();
        } else {
          this.$emit("click");
        }
      }
    },
  },
};
</script>

<style scoped>
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.2rem 0.4rem !important;
  font-size: 10pt;
}
</style>